import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import ServiceIcon from '../../components/services/serviceIcon'
import * as S from '../../styles/pages/servicesStyle'
import * as Shape1 from '../../assets/images/static/shape1.svg'
import * as Shape2 from '../../assets/images/static/shape2.svg'

export default function Zabiegi({ data }) {
  const imagesMap = new Map()
  data.servicesImages.edges.forEach(elem => {
    imagesMap.set(elem.node.name, elem.node.publicURL)
  })

  const slides = data.servicesList.edges.map(elem => {
    return (
      <ServiceIcon
        key={elem.node.frontmatter.id}
        title={elem.node.frontmatter.name}
        text={elem.node.frontmatter.text}
        link={elem.node.frontmatter.link}
        image={imagesMap.get(elem.node.frontmatter.id)}
        imageAlt={elem.node.frontmatter.imageAlt}
      />
    )
  })
  return (
    <Layout>
      <S.ServicesSection>
        <S.ServicesShape1>
          <Shape1 />
        </S.ServicesShape1>
        <S.ServicesShape2>
          <Shape2 />
        </S.ServicesShape2>
        <S.MainHeader>Zabiegi</S.MainHeader>
        <S.ServicesContainer>{slides}</S.ServicesContainer>
      </S.ServicesSection>
    </Layout>
  )
}

export const query = graphql`
  query ServicesQuery {
    servicesList: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "markdowns/zabiegi/list/.*.md/" } }
    ) {
      edges {
        node {
          frontmatter {
            name
            text
            id
            link
            imageAlt
          }
        }
      }
    }
    servicesImages: allFile(
      filter: { absolutePath: { regex: "assets/images/services/.*.jpg/" } }
    ) {
      edges {
        node {
          name
          absolutePath
          publicURL
        }
      }
    }
  }
`
